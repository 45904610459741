


















import Vue from 'vue';
import Recommended from '@/components/Recommended.vue';

export default Vue.extend({
	components: { Recommended }
});
