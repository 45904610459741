





























import Vue from 'vue';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default Vue.extend({
	components: { swiper, swiperSlide },
	data () {
		return {
			options: {
				autoHeight: false,
				// height: 460,
				slidesPerView: 1,
				centeredSlides: true,
				navigation: {
					nextEl: '.swiper-btn.next',
					prevEl: '.swiper-btn.prev'
				},
				fadeEffect: {
					crossFade: true
				}
			}
		};
	},
	beforeMount () {
		if (!this.$store.getters.recSlider) {
			this.$store.dispatch('fetch', {
				storeKey: 'recSlider',
				fragment: 'slider/1/',
				endpoint: 'module',
				store: true
			});
		}
	},
	computed: {
		slider () : any { return this.$store.getters.recSlider; },
		lang () : any { return (this as any).$lang(); }
	}
});
